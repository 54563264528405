import { Component, Vue } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import { DEFAULT_TABLE_OPTIONS, TableOptions } from "@/api/request";
import { SearchRequest } from "@/api/authcode/request";
import { SearchItem } from "@/api/authcode/response";
import AuthcodeSearch from "@/store/authcode/search";
import ShopStampGet from "@/store/shop-stamp/get";
import ShopPointGet from "@/store/shop-point/get";
import ShopCouponGet from "@/store/shop-coupon/get";
import ShopTicketGet from "@/store/shop-ticket/get";
import Flash, { ErrorAlert } from "@/store/common/flash";
import { AUTH_TYPE } from "@/api/constants";

@Component({ components: { UlContentHeader, UlBreadcrumbs, UIDataTable } })
export default class Index extends Vue {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "認証コード";
  headingSub = "Auth code";
  breadCrumbs = [
    { text: "設定", disabled: true },
    { text: "認証コード", disabled: true },
    { text: "店舗一覧", disabled: true }
  ];

  // テーブルヘッダ
  tableHeaders = [
    { text: "店舗ID", value: "shopId" },
    { text: "店舗名", value: "shopName" },
    { text: "認証種別", value: "typeName" },
    { text: "名称", value: "title" },
    { text: "認証コード", value: "code", sortable: false },
    { label: "編集", text: "", value: "edit", sortable: false }
  ];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };
  // ------------

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoading = false;
  //NEW_DEV-707 cyber start
  isShowPoint = 0;
  isShowCoupon = 0;
  isShowStamp = 0;
  isShowTicket = 0;
  //NEW_DEV-707 cyber end

  // 検索入力オプション
  inputOptions = {
    selectStampIds: null as number[] | null,
    selectPointIds: null as number[] | null,
    selectCouponIds: null as number[] | null,
    selectTicketIds: null as number[] | null
  };

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions = DEFAULT_TABLE_OPTIONS;
  // ------------

  /**
   * テーブルに表示するアイテムリスト（UIDataTableコンポーネントに渡す）
   */
  get tableItems() {
    return AuthcodeSearch.getItems;
  }

  /**
   * 総件数（UIDataTableコンポーネントに渡す）
   */
  get totalCount() {
    return AuthcodeSearch.getTotalCount;
  }

  get shopStampItems() {
    return ShopStampGet.getItems;
  }

  get shopPointItems() {
    return ShopPointGet.getItems;
  }

  get shopCouponItems() {
    return ShopCouponGet.getItems;
  }

  get shopTicketItems() {
    return ShopTicketGet.getItems;
  }

  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   */
  async created() {
    await AuthcodeSearch.restore(this.inputOptions as SearchRequest);
    const request = AuthcodeSearch.getSearchRequest;
    // 検索入力オプション
    request.selectStampIds &&
      (this.inputOptions.selectStampIds = request.selectStampIds);
    request.selectPointIds &&
      (this.inputOptions.selectPointIds = request.selectPointIds);
    request.selectCouponIds &&
      (this.inputOptions.selectCouponIds = request.selectCouponIds);
    request.selectTicketIds &&
      (this.inputOptions.selectTicketIds = request.selectTicketIds);

    // 検索テーブルオプション
    request.page && (this.tableOptions = request);
    await this.search();
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await AuthcodeSearch.clearResponse();
    await ShopStampGet.clearResponse();
    await ShopPointGet.clearResponse();
    await ShopCouponGet.clearResponse();
    await ShopTicketGet.clearResponse();
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    await this.search();
  }

  /**
   * 検索ボタンがクリックされた際のコールバック
   */
  async searchClickCallback() {
    await Flash.clear();
    await this.search();
  }

  /**
   * テーブル行の編集ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  async editClickCallback(item: SearchItem) {
    const authTypes = AUTH_TYPE.filter(type => {
      return type.text === item.typeName;
    });
    if (authTypes.length <= 0) {
      return;
    }

    let query = {
      authType: authTypes[0].value,
      stampAuthId: String(item.id)
    };

    let params = {
      id: String(item.id)
    };
    if (item.typeName !== "スタンプカード") {
      query.stampAuthId = item.id;
      delete query.stampAuthId;
    } else {
      params.id = String(item.shopId);
    }

    await this.$router.push({
      name: "auth-code-edit",
      params: params,
      query: query
    });
  }

  /**
   * スタンプカード店舗情報の一覧を取得する処理
   */
  async fetchShopStampList(): Promise<boolean> {
    if (ShopStampGet.isSuccess) {
      return true;
    }

    this.isLoading = true;
    await ShopStampGet.get();
    if (!ShopStampGet.isSuccess) {
      await Flash.setErrorNow({
        message: ShopStampGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
    return ShopStampGet.isSuccess;
  }

  /**
   * ポイントカード店舗情報の一覧を取得する処理
   */
  async fetchShopPointList(): Promise<boolean> {
    if (ShopPointGet.isSuccess) {
      return true;
    }

    this.isLoading = true;
    await ShopPointGet.get();
    if (!ShopPointGet.isSuccess) {
      await Flash.setErrorNow({
        message: ShopPointGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
    return ShopPointGet.isSuccess;
  }

  /**
   * クーポン店舗情報の一覧を取得する処理
   */
  async fetchShopCouponList(): Promise<boolean> {
    if (ShopCouponGet.isSuccess) {
      return true;
    }

    this.isLoading = true;
    await ShopCouponGet.get();
    if (!ShopCouponGet.isSuccess) {
      await Flash.setErrorNow({
        message: ShopCouponGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
    return ShopCouponGet.isSuccess;
  }

  /**
   * デジタルチケット店舗情報の一覧を取得する処理
   */
  async fetchShopTicketList(): Promise<boolean> {
    if (ShopTicketGet.isSuccess) {
      return true;
    }

    this.isLoading = true;
    await ShopTicketGet.get();
    if (!ShopTicketGet.isSuccess) {
      await Flash.setErrorNow({
        message: ShopTicketGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
    return ShopTicketGet.isSuccess;
  }

  /**
   * 検索処理
   */
  async search() {
    // NEW_DEV-707 cyber start
    this.isLoading = true;
    let request = this.createRequest();
    await AuthcodeSearch.search(request);
    if (!AuthcodeSearch.isSuccess) {
      await Flash.setErrorNow({
        message: AuthcodeSearch.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }

    let isSuccess;
    let isShowPointFlg = 0;
    let isShowStampFlg = 0;
    let isShowCouponFlg = 0;
    let isShowTicketFlg = 0;
    if (AuthcodeSearch.searchResponse.results.authoritySelect.pointReadFlg) {
      isSuccess = await this.fetchShopPointList();
      if (!isSuccess) {
        return;
      }
      isShowPointFlg = 1;
    }

    if (AuthcodeSearch.searchResponse.results.authoritySelect.stampReadFlg) {
      isSuccess = await this.fetchShopStampList();
      if (!isSuccess) {
        return;
      }
      isShowStampFlg = 1;
    }

    if (AuthcodeSearch.searchResponse.results.authoritySelect.couponReadFlg) {
      isSuccess = await this.fetchShopCouponList();
      if (!isSuccess) {
        return;
      }
      isShowCouponFlg = 1;
    }

    if (AuthcodeSearch.searchResponse.results.authoritySelect.ticketReadFlg) {
      isSuccess = await this.fetchShopTicketList();
      if (!isSuccess) {
        return;
      }
      isShowTicketFlg = 1;
    }

    this.isShowPoint = isShowPointFlg;
    this.isShowStamp = isShowStampFlg;
    this.isShowCoupon = isShowCouponFlg;
    this.isShowTicket = isShowTicketFlg;
    // NEW_DEV-707 cyber end
    this.isLoading = false;
  }

  /**
   * dataから検索用のリクエストを作成する
   */
  private createRequest(): SearchRequest {
    const request = this.tableOptions as SearchRequest;
    if (this.inputOptions.selectStampIds) {
      request.selectStampIds = this.inputOptions.selectStampIds;
    } else {
      delete request.selectStampIds;
    }

    if (this.inputOptions.selectPointIds) {
      request.selectPointIds = this.inputOptions.selectPointIds;
    } else {
      delete request.selectPointIds;
    }

    if (this.inputOptions.selectCouponIds) {
      request.selectCouponIds = this.inputOptions.selectCouponIds;
    } else {
      delete request.selectCouponIds;
    }

    if (this.inputOptions.selectTicketIds) {
      request.selectTicketIds = this.inputOptions.selectTicketIds;
    } else {
      delete request.selectTicketIds;
    }

    return request;
  }
}
